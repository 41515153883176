<template>
  <v-tooltip
    :top="computedTop"
    :bottom="bottom"
    :left="left"
    :right="right"
    :open-delay="openDelay"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <icon-mark
          v-on="$listeners"
          :icon-name="computedIconName"
          :icon-mark-props="iconMarkProps"
          :class="classIcon"
          :color="color"
          :small="small"
          :x-small="xSmall"
          :dense="dense"
        />
      </div>
    </template>
    <span>{{computedTooltipText}}</span>
  </v-tooltip>
</template>

<script>

export default {
  components: {
    IconMark: () => import('@/components/icon-mark')
  },
  computed: {
    computedTop () {
      if (!this.bottom && !this.left && !this.right) {
        return true
      }
      return false
    },
    computedIconName () {
      return this.iconName
    },
    computedTooltipText () {
      return this.tooltipText
    }
  },
  props: {
    classIcon: [Object, String],
    dense: Boolean,
    refIcon: String,
    color: String,
    iconName: String,
    tooltipText: String,
    disabled: Boolean,
    openDelay: {
      type: Number,
      default: 200
    },
    top: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
    small: Boolean,
    xSmall: Boolean,
    iconMarkProps: Object
  }
}
</script>
